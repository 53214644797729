<template>
	<div class="main-contents display">
		<div class="tit">
			<SelectComp type="text" :value="div" cdId="PRO134" v-if="div"/><template v-else>추천 프로젝트 노출</template><nbsp/>
			<template v-if="seq">수정</template><template v-else>등록</template>
			<template v-if="mdiv">(<SelectComp type="text" :value="mdiv" cdId="PRO135"/>)</template>
			<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)">
		</div>
		<div class="search-box btn">
			<div class="sub_btn dl" @click="delBanner()" v-if="seq">삭제</div>
		</div>
		<!-- 배너등록 -->
		<div class="banner_box">
			<div class="list">
				<div class="label">프로젝트명</div>
				<InputComp title="프로젝트명" v-model="data.projectNm" rules="required"/>
			</div>
			<div class="list">
				<div class="label">프로젝트일련번호</div>
				<InputComp title="프로젝트일련번호" v-model="data.projectSeq"/>
			</div>
			<div class="list">
				<div class="label">모집인원수</div>
				<InputComp title="모집인원수" v-model="data.persCnt" rules="required"/>
			</div>
			<div class="list">
				<div class="label">모집종료일자</div>
				<SelectDate title="모집종료일자" tagNm="div" v-model="data.recrendYyyyMmDd" type="Date" from="Y-10" to="Y10" rules="required"/>
			</div>
			<div class="list">
				<div class="label">노출기간</div>
				<SelectDate title="노출시작시간" tagNm="div" v-model="data.applyStartDate" type="HOUR" from="Y-10" to="Y10" rules="required"/>
				<p>~</p> 
				<SelectDate title="노출종료시간" tagNm="div" v-model="data.applyEndDate" type="HOUR" from="Y-10" to="Y10" rules="required"/>
			</div>
			<div class="list">
				<div class="label">노출여부</div>
				<SelectComp title="노출여부" tagName="div" class="radio" type="radio" v-model="data.useYn" list="Y:노출,N:노출종료" rules="required"/>
			</div>
			<div class="list bn_img">
				<div class="label">이미지</div>
				<file-select title="이미지" tagNm="div" type="admin" v-model="temp.bannerImg" :name="data.imgFileNm" accept=".jpg,.jpeg,.png,.bmp,.gif" rules="required"/>
				<p v-if="descMsg[div]">{{descMsg[div].banner}}</p>
			</div>
			<!-- <div class="list link">
				<div class="label">링크 URL</div>
				<InputComp title="링크 URL" type="url" v-model="data.linkUrl" />
				<p>※ URL 주소 앞에 절대주소(http://, https://)가 필수로 입력되어야 합니다!</p>
			</div> -->
			
		</div>
		<div class="select_btn">
			<div class="btn_01" @click="$router.go(-1)">취소</div>
			<div class="btn_02" @click="save()">적용</div>
		</div>
	</div>
</template>

<script>
import fileSelect from "@/components/FileSelect.vue";
export default {
	components: { fileSelect },
	data() {
		return {
			seq: this.$route.query.banner,
			div: this.$route.query.div,
			mdiv: this.$route.query.mdiv,

			descMsg : {
				'01': {
					'banner' : '※ 이미지 사이즈는 1920 X 450 (픽셀)로 업로드 해 주세요!',
					'detail' : '※ 이미지의 가로사이즈는 1920 (픽셀) 이하로 업로드 해 주세요!',
				},
				'02': {
					'banner' : '※ 이미지 사이즈는 1225 X 1100 (픽셀) 이하로 업로드 해 주세요!',
					'detail' : '※ 이미지의 가로사이즈는 1920 (픽셀) 이하로 업로드 해 주세요!',
				},
				'03': {
					'banner' : '※ 이미지의 가로사이즈는 1920 (픽셀) 이하로 업로드 해 주세요!',
					'detail' : '※ 이미지의 가로사이즈는 1920 (픽셀) 이하로 업로드 해 주세요!',
				},
			},

			data:{},
			temp: {},

			now: new Date().format('yyyyMMddHHmm'),
		};
	},
	beforeMount(){
		if(this.seq){
            this.$.httpPost('/api/main/bnr/getRecomendPrjt', {recmendProjectSeq:this.seq})
                .then(res => {
                    this.data = res.data;
                }).catch(this.$.httpErrorCommon);
		} else {
			this.data.bannerDivCd     = this.div;
			this.data.bannerMberDivCd = this.mdiv;
		}
	},
	methods : {
		save(){
			var files = [];
			if(this.temp.bannerImg instanceof File){
				files.push(this.temp.bannerImg);
                this.data.bannerImgFileNm = this.temp.bannerImg.name;
				this.data.bannerImgFileYn = 'Y';
			}
			
			this.$.fileUpload('IMG', files, '/api/main/bnr/saveRecomendPrjt', this.data)
                .then(() => {
					alert('저장이 완료되었습니다.');
                    this.$router.go(-1);
                }).catch(this.$.httpErrorCommon);
		},
		delBanner(){
			// console.log('delBanner start', JSON.stringify(this.checks));
			var list = [ this.data ];
			this.$.popup('/adm/man/MAN901P02', {list})
				.then(res => {
					if(res) {
						// 회원가입 화면으로 이동
						//this.getList();
						this.$router.go(-1);
					}
				});
			
		}
	}
}
</script>